import SectionTitle from '../../components/Common/SectionTitle';
import SingleService from '../../components/Service/SingleService';

import serviceIcon1 from '../../assets/img/New_Icon/sourcing.jpg';
import serviceIcon2 from '../../assets/img/New_Icon/distribution.jpg';
import serviceIcon3 from '../../assets/img/New_Icon/cost-effective.jpg';
import serviceIcon4 from '../../assets/img/New_Icon/support.jpg';
import serviceIcon5 from '../../assets/img/New_Icon/inventory.jpg';
import serviceIcon6 from '../../assets/img/New_Icon/testing.jpg';

const Service = () => {

    return (
        <div id="rs-service" className="rs-services main-home gray-bg pt-110 pb-120 md-pt-70 md-pb-70">
            <div className="container">
                <SectionTitle
                    sectionClass="sec-title2 text-center mb-46"
                    subtitleClass="sub-text"
                    subtitle="Services"
                    titleClass="title"
                    title="We offer an array of services to customers so that they can get one-stop solutions without any inconvenience. "
                    effectClass="heading-line"
                />
                <div className="row">
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleService 
                            itemClass="services-item"
                            serviceImage={serviceIcon1}
                            serviceURL="software-development"
                            Title="Sourcing" 
                            Text="We help you source electronic components, no matter how difficult they are." 
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleService 
                            itemClass="services-item"
                            serviceImage={serviceIcon2}
                            serviceURL="sofware-item"
                            Title="Distribution" 
                            Text="HIFI offers a variety of services, including supply chain solutions for excess inventory, slow-moving stock, and component shortages, many of which can be tailored." 
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleService 
                            itemClass="services-item"
                            serviceImage={serviceIcon3}
                            serviceURL="analytic-solutions"
                            Title="Cost-Effective" 
                            Text="Finding production-related materials at the lowest cost without sacrificing product quality is perhaps the biggest problem that purchasing departments confront." 
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 md-mb-30">
                        <SingleService 
                            itemClass="services-item"
                            serviceImage={serviceIcon4}
                            serviceURL="clould-devOps"
                            Title="Support for Buying" 
                            Text="If you are having purchasing issues that are having a negative impact on your country, we can help you by assigning a dedicated buyer with on-site support." 
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 sm-mb-30">
                        <SingleService 
                            itemClass="services-item"
                            serviceImage={serviceIcon5}
                            serviceURL="product-design"
                            Title="Inventory" 
                            Text="It can be very expensive to manage your inventory on your own. HIFI offers you a study of several models for inventory ownership while simultaneously managing your inventory." 
                        />
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <SingleService 
                            itemClass="services-item"
                            serviceImage={serviceIcon6}
                            serviceURL="data-center"
                            Title="Testing" 
                            Text="We do more than fifty functional tests per day and provide comprehensive functionality lab testing." 
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Service;
import React from 'react';
import Header from '../components/Layout/Header';
import Footer from '../components/Layout/Footer';
import SearchModal from '../components/Layout/Header/SearchModal';
import ScrollToTop from '../components/Common/ScrollTop';

const Thanks = () => {

    return (
        <React.Fragment>
            <Header
                parentMenu='page'
                secondParentMenu='pages'
                activeMenu='/faq'
            />
            <div className="container">
                    <div className="row align-items-center">
            <div className="col-lg-12 md-pl-14">
                <br></br><br></br>
               <h3>Thanks for your submission</h3>
               <p>A representative will contact you within 24 hours or the next business day. Thank you for your enquiry.</p>
               <br></br><br></br>
                
                <ScrollToTop />
                {/* scrolltop-end */}
            </div></div></div>
            <Footer />
            <SearchModal />
        </React.Fragment>

    );
}


export default Thanks;
import React from "react";
import { Link } from "react-router-dom";

import Logo from "../../../assets/img/New_Icon/HIFI.png";

const TopHeader = () => {
  return (
    <div className="topbar-area hidden-md">
      <div className="container">
        <div className="row">
          <div className="col-lg-2">
            <div className="logo-part">
              <Link to="/">
                <img
                  src={Logo}
                  alt="Logo"
                  //style={{ height: "50px", width: "200px" }}
                />
              </Link>
            </div>
          </div>
          <div className="col-lg-10 text-right">
            <ul className="rs-contact-info">
              <li className="contact-part">
                <i className="flaticon-location"></i>
                <span className="contact-info">
                  <span>Address</span>
                  2173 Magwa Crescent Gateway West STE 300, <br></br>Johannesburg, 2066, South Africa
                </span>
              </li>
              <li className="contact-part">
                <i className="flaticon-email"></i>
                <span className="contact-info">
                  <span>E-mail</span>
                  <a href="mailto:electronicsupport@hifielectro.com">electronicsupport@hifielectro.com</a>
                </span>
              </li>
              
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopHeader;

import React  from 'react';
import { Link } from 'react-router-dom';
import SectionTitle from '../../components/Common/SectionTitle';
import SingleProcess from '../../components/Common/Process';
import feature2 from '../../assets/img/New_Icon/feature2.png';
import feature3 from '../../assets/img/New_Icon/feature3.png';
import feature4 from '../../assets/img/New_Icon/feature4.png';

const Process = () => {

    return (
        <div className="rs-process pt-180 pb-120 md-pt-70 md-pb-70">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-4 md-mb-40">
                        <div className="process-wrap">
                            <SectionTitle
                                sectionClass="sec-title mb-30"
                                subtitleClass="sub-text new"
                                subtitle="FEATURED CAPABILITIES"
                                titleClass="title white-color"
                                title="We make sure that all our customers are benefited with our featured capabilities"
                            />
                        </div>
                    </div>
                    <div className="col-lg-8 pl-30 md-pl-15">
                        <div className="row">
                            <div className="col-md-6 mb-40">
                                <SingleProcess 
                                    processNumber="1"
                                    processTitle="Reliable Distribution"
                                    processText="HIFI is an independent distributor of electronic components. Gain access to the best manufacturers and pricing for your requirements."
                                />                                
                            </div>
                            <div className="col-md-6 mb-40">                                
                                <SingleProcess 
                                    processNumber="2"
                                    processTitle="Logistics "
                                    processText="As your primary vendor and we’ll coordinate everything. Reduce transportation and warehousing costs by consolidating your supply chain with HIFI."
                                />
                            </div>
                            <div className="col-md-6 sm-mb-40">
                                <SingleProcess 
                                    processNumber="3"
                                    processTitle="Inventory"
                                    processText="HIFI has ready inventory of electronic components which helps us supply products almost instantly."
                                />
                            </div>
                            <div className="col-md-6">
                                <SingleProcess 
                                    processNumber="4"
                                    processTitle="Wide Network"
                                    processText="We have associations with manufacturers all across the globe. If you need electronic part, we are sure that we can find it for you."
                                />
                            </div>
                        </div>
                    </div>
                </div>

                
            </div>

            <div className="container pt-130 pb-190">
                        <div className="row">
                            
                    <div className="col-lg-4">
                    <img src={feature2} alt="" /><br></br><br></br>
                    <h5>IMMEDIATE RESPONSE</h5>
                    </div>
                    <div className="col-lg-4">
                    <img src={feature3} alt="" /><br></br><br></br>
                    <h5>TECHNICAL ASSISTANCE</h5>
                    </div>
                    <div className="col-lg-4">
                    <img src={feature4} alt="" /><br></br><br></br>
                    <h5>ASSURANCE</h5>
                    </div>
                    </div>
                    </div>
                    
        </div>
    );
}

export default Process;
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

const RSMobileMenu = ({ setMenuOpen, menuOpen, activeMenu }) => {
  const [home, setHome] = useState(false);
  const [homeMultipage, setHomeMultipage] = useState(false);
  const [homeOnepage, setHomeOnepage] = useState(false);
  const [about, setAbout] = useState(false);
  const [services, setServices] = useState(false);
  const [blog, setBlog] = useState(false);
  const [pages, setPages] = useState(false);
  const [servicePages, setServicePages] = useState(false);
  const [caseStudies, setCaseStudies] = useState(false);
  const [shopPages, setShopPages] = useState(false);

  const openMobileMenu = (menu) => {
    if (menu === "home") {
      setHome(!home);
      setHomeMultipage(false);
      setHomeOnepage(false);
      setAbout(false);
      setServices(false);
      setBlog(false);
      setPages(false);
      setServicePages(false);
      setCaseStudies(false);
      setShopPages(false);
    } else if (menu === "homeMultipage") {
      setHome(true);
      setHomeMultipage(!homeMultipage);
      setHomeOnepage(false);
      setAbout(false);
      setServices(false);
      setBlog(false);
      setPages(false);
      setServicePages(false);
      setCaseStudies(false);
      setShopPages(false);
    } else if (menu === "homeOnepage") {
      setHome(true);
      setHomeMultipage(false);
      setHomeOnepage(!homeOnepage);
      setAbout(false);
      setServices(false);
      setBlog(false);
      setPages(false);
      setServicePages(false);
      setCaseStudies(false);
      setShopPages(false);
    } else if (menu === "about") {
      setHome(false);
      setHomeMultipage(false);
      setHomeOnepage(false);
      setAbout(!about);
      setServices(false);
      setBlog(false);
      setPages(false);
      setServicePages(false);
      setCaseStudies(false);
      setShopPages(false);
    } else if (menu === "services") {
      setHome(false);
      setHomeMultipage(false);
      setHomeOnepage(false);
      setAbout(false);
      setServices(!services);
      setBlog(false);
      setPages(false);
      setServicePages(false);
      setCaseStudies(false);
      setShopPages(false);
    } else if (menu === "blog") {
      setHome(false);
      setHomeMultipage(false);
      setHomeOnepage(false);
      setAbout(false);
      setServices(false);
      setBlog(!blog);
      setPages(false);
      setServicePages(false);
      setCaseStudies(false);
      setShopPages(false);
    } else if (menu === "pages") {
      setHome(false);
      setHomeMultipage(false);
      setHomeOnepage(false);
      setAbout(false);
      setServices(false);
      setBlog(false);
      setPages(!pages);
      setServicePages(false);
      setCaseStudies(false);
      setShopPages(false);
    } else if (menu === "servicePages") {
      setHome(false);
      setHomeMultipage(false);
      setHomeOnepage(false);
      setAbout(false);
      setServices(false);
      setBlog(false);
      setPages(true);
      setServicePages(!servicePages);
      setCaseStudies(false);
      setShopPages(false);
    } else if (menu === "caseStudies") {
      setHome(false);
      setHomeMultipage(false);
      setHomeOnepage(false);
      setAbout(false);
      setServices(false);
      setBlog(false);
      setPages(true);
      setServicePages(false);
      setCaseStudies(!caseStudies);
      setShopPages(false);
    } else if (menu === "shopPages") {
      setHome(false);
      setHomeMultipage(false);
      setHomeOnepage(false);
      setAbout(false);
      setServices(false);
      setBlog(false);
      setPages(true);
      setServicePages(false);
      setCaseStudies(false);
      setShopPages(!shopPages);
    }
  };

  return (
    <div className="container relative">
      <div className={menuOpen ? "mobile-menu-part open" : "mobile-menu-part"}>
        <div className="mobile-menu">
          <ul className="nav-menu">
            <li className={pages ? "current-menu-item" : ""}>
              <Link to="" as="/">
                Home
              </Link>
            </li>
            <li className={blog ? "current-menu-item" : ""}>
              <Link to="/about">About Us</Link>
            </li>
            <li>
              <Link
                to="/contact"
                className={activeMenu === "/contact" ? "active-menu" : ""}
              >
                RFQ
              </Link>
            </li>
            <li>
              <Link
                to="/featured-capabilities"
                className={activeMenu === "/contact" ? "active-menu" : ""}
              >
                Featured Capabilities
              </Link>
            </li>
            <li>
              <Link
                to="/service"
                className={activeMenu === "/contact" ? "active-menu" : ""}
              >
                Service
              </Link>
            </li>
            <li>
              <Link
                to="/terms"
                className={activeMenu === "/contact" ? "active-menu" : ""}
              >
                Terms
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default RSMobileMenu;

import React from 'react';
import { Link } from 'react-router-dom';
import SectionTitle from '../../components/Common/SectionTitle';
import ProgressBar from '../../components/Elements/Progress/ProgressBar';

import aboutPart1 from '../../assets/img/New_Icon/iStock-1210757738.jpg';
import aboutPart2 from '../../assets/img/New_Icon/iStock-1390220597.jpg';
import aboutPart3 from '../../assets/img/New_Icon/iStock-1388779524.jpg';

import about2 from '../../assets/img/New_Icon/about2.png';
import about3 from '../../assets/img/New_Icon/about3.png';
import about4 from '../../assets/img/New_Icon/about4.png';
import about5 from '../../assets/img/New_Icon/about5.png';
import about6 from '../../assets/img/New_Icon/about6.png';
import about7 from '../../assets/img/New_Icon/about7.png';

const About = (props) => {
    return (
        <div id="rs-about" className="rs-about pt-130 pb-190 md-pt-80 md-pb-80 sm-pt-0">
            <div className="container">
                <div className="row">
                    <div className="col-lg-5 col-md-12">
                        <div className="img-wrap">
                            <img className="main" src={aboutPart1} alt="" />
                            <div className="ly1">
                                <img className="dance" src={aboutPart2} alt="" />
                            </div>
                            <div className="ly2">
                                <img className="dance2" src={aboutPart3} alt="" />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-7 col-md-12 pl-40 md-pl-14 md-pt-240 sm-pt-66">
                        {/* Section Title Start */}
                        <SectionTitle
                            sectionClass="sec-title mb-30"
                            subtitleClass="sub-text"
                            subtitle="About"
                            titleClass="title pb-20"
                            title="Electronic Component Sourcing Partners"
                            descClass="desc pr-90 md-pr-0"
                            description=""
                        />
                        <p>We are completely immersed in electronic components. We are a creative business that works with love and passion. We take pride in assisting our customers in locating the highest quality electronic components they require and work hard to provide best outcomes for any order. We are with you all the way through.</p>
                        <h3>Why Us?</h3>
                        <p>We specialize in locating old electronic parts that are challenging to find in anywhere across the globe. We have connections with suppliers for years who help us locate these parts in highest quality with ease.</p>
                        <b>We work with multiple industries. The ones below are just some of which we have the highest number of permanent contracts with.</b>
                        <ul>
                            <li>Agriculture</li>
                            <li>Aviation</li>
                            <li>IT</li>
                            <li>Infrastructure</li>
                            <li>Healthcare</li>
                            <li>Military</li>
                        </ul>
                    </div>

                    
                </div>
                </div>
                        {/* Section Title End */}
                        <div className="container pt-130 pb-190">
                        <div className="row">
                            
                    <div className="col-lg-4">
                    <img src={about2} alt="" /><br></br><br></br>
                    <img src={about3} alt="" />
                    </div>
                    <div className="col-lg-4">
                    <img src={about4} alt="" /><br></br><br></br>
                    <img src={about5} alt="" />
                    </div>
                    <div className="col-lg-4">
                    <img src={about6} alt="" /><br></br><br></br>
                    <img src={about7} alt="" />
                    </div>
                                {/* Skill Bar Start */}
                                {/* <div className="rs-skillbar style1">
                                    <ProgressBar
                                        completed={92}
                                        height={7}
                                        boxbg={"#eeeeee"}
                                        figurebg={"#f00"}
                                        ProgressBox="progress-box"
                                        ProgressFigure="progress-figure"
                                        ProgressCount="Progress-count"
                                        ProgressLabel="progress-label"
                                        label="Software Development"
                                        ProgressParent="main-div"
                                    />
                                    <ProgressBar
                                        completed={80}
                                        height={7}
                                        boxbg={"#eeeeee"}
                                        figurebg={"#f00"}
                                        ProgressBox="progress-box"
                                        ProgressFigure="progress-figure"
                                        ProgressCount="Progress-count"
                                        ProgressLabel="progress-label"
                                        label="Cyber Security"
                                        ProgressParent="main-div"
                                    />
                                    <ProgressBar
                                        completed={95}
                                        height={7}
                                        boxbg={"#eeeeee"}
                                        figurebg={"#f00"}
                                        ProgressBox="progress-box"
                                        ProgressFigure="progress-figure"
                                        ProgressCount="Progress-count"
                                        ProgressLabel="progress-label"
                                        label="Artificial Intelligence"
                                        ProgressParent="main-div"
                                    />
                                    <ProgressBar
                                        completed={78}
                                        height={7}
                                        boxbg={"#eeeeee"}
                                        figurebg={"#f00"}
                                        ProgressBox="progress-box"
                                        ProgressFigure="progress-figure"
                                        ProgressCount="Progress-count"
                                        ProgressLabel="progress-label"
                                        label="Web Development"
                                        ProgressParent="main-div"
                                    />
                                </div> */}
                                {/* Skill Bar End */}
                                {/* <div className="btn-part mt-54">
                                    <Link to="/about" className="readon learn-more">Learn More</Link>
                                </div> */}
                          
                        </div>
                    
                
            </div>
        </div>
    );
}

export default About;
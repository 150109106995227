import React, { useState } from 'react';
import ModalVideo from 'react-modal-video';
// import AppointmentForm from '../Appointment';

const BannerDefault = () => {

    const [isOpen, setIsOpen] = useState(false);
    const openModal = () => setIsOpen(!isOpen);

    return (
        <React.Fragment>

            {/* <!-- banner section start --> */}
            <div className="rs-banner style1">
                <ModalVideo channel='youtube' isOpen={isOpen} videoId='vWLcyFtni6U' onClose={() => { openModal(); }} />
                <div className="banner-height pt-100 pb-100 md-pt-80 md-pb-80">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 col-md-12 pt-40 pr-140 md-pt-0 md-mb-60 md-pr-14">
                                
                            </div>
                            <div className="col-lg-6 col-md-12 pl-70 lg-pl-30 md-pl-14">
                                {/* <AppointmentForm /> */}
                                <div className="banner-content">
                                    <h2 className="bnr-title" data-animation="fadeInLeft" data-delay=".3s">Welcome to HIFI – Your Trusted Electronic Component Sourcing Partner</h2>
                                    <p className="bnr-desc" data-animation="fadeInLeft" data-delay=".5s">With locations in the Americas and Asia, our company runs an extremely efficient worldwide sourcing network and offers contract manufacturers (CMs) and major original equipment manufacturers (OEMs) a wide choice of integrated sourcing solutions.</p>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- banner section end --> */}

        </React.Fragment>
    );
}

export default BannerDefault;
import React from 'react';
import ScrollToTop from '../../components/Common/ScrollTop';
import BannerDefault from '../../components/Banner/BannerDefault';
import home2 from '../../assets/img/New_Icon/home2.png'
import home3 from '../../assets/img/New_Icon/home3.png'
import home4 from '../../assets/img/New_Icon/home4.png'
import { Link } from "react-router-dom";

const HomeMain = () => {
	return (
		<React.Fragment>
			{/* banner-start */}
			<BannerDefault />


			<div className="rs-process pt-180 pb-120 md-pt-70 md-pb-70">
            <div className="container">
                <div className="row align-items-top">
                    <div className="col-lg-6 md-mb-40 ">
                    <h3>Your trusted source Electronic component supplier</h3>
                	<p>We present ourselves as a procurement agent and dealer to buy electronic components from pre-selected vendors all over the world for the benefit of and on behalf of our clients. </p>
					<h4>HIFI supports OEMs and CEMs internationally as both independent component distributors and manufacturer agents.</h4>	
					<p>HIFI has built a strondg global network with a wide range of manufacturers over the years, enabling us to serve various customer requirements. We make use of our extensive sourcing expertise to optimize the distinctive supply chains of our customers. We do not place any restrictions on our clients based on their location or distance thanks to our broad network of service systems and free online counseling. Information on every facet of our services is therefore easily accessible.</p>
					<Link to="/contact" className="btn1">Get in Touch</Link>
					
				</div>	
				<div className="col-lg-6 md-mb-40">
					<img src={home2} alt=""></img>
					<img src={home3} alt=""></img>
					<img src={home4} alt=""></img>
				</div>
				</div>	
			</div>
			</div>		
			
			{/* banner-start */}

			{/* about-area-start */}
			{/* <About /> */}
			{/* about-area-end */}

			{/* services-area-start */}
			{/* <Service /> */}
			{/* services-area-end */}

			{/* call us section start */}
			{/* <CallUs /> */}
			{/* call us section end */}
			
			{/* process-area-start */}
			{/* <Process /> */}
			{/* process-area-end */}

			{/* project-area-start */}
			{/* <Project /> */}
			{/* project-area-end */}

			{/* Pricing-area-start */}
			{/* <Pricing /> */}
			{/* Pricing-area-end */}

			{/* Testimonial-area-start */}
			{/* <Testimonial /> */}
			{/* Testimonial-area-end */}

			{/* Blog-area-start */}
			{/* <Blog /> */}
			{/* Blog-area-end */}	

			{/* scrolltop-start */}
			<ScrollToTop />
			{/* scrolltop-end */}
		</React.Fragment>
	);
}

export default HomeMain;
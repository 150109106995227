import React from 'react';
import SectionTitle from '../Common/SectionTitle';

const ContactInfo = (props) => {
    const { contactBoxClass } = props;
    return (
        <div className={contactBoxClass ? contactBoxClass : 'contact-box'}>
            <SectionTitle
                sectionClass="sec-title mb-44"
                subtitleClass="sub-text new-text white-color"
                subtitle="RFQ"
                titleClass="title white-color"
                title="Send Us Your Detailed Requirement Here"
            />
            
            <div className="address-box mb-24">
                <div className="address-icon">
                    <i className="fa fa-home"></i>
                </div>
                <div className="address-text">
                    <span className="label">Email:</span>
                    <a href="mailto:electronicsupport@hifielectro.com">electronicsupport@hifielectro.com</a>
                </div>
            </div>
            <div className="address-box">
                <div className="address-icon">
                    <i className="fa fa-map-marker"></i>
                </div>
                <div className="address-text">
                    <span className="label">Address:</span>
                    <div className="desc">2173 Magwa Crescent Gateway West STE 300, Johannesburg, 2066, South Africa</div>
                </div>
            </div>
        </div>
    );

}

export default ContactInfo;
import React from "react";
import { Link } from "react-router-dom";
import FooterBottom from "./FooterBottom";

import footerLogo1 from "../../../assets/img/New_Icon/HIFI.png";

const Footer = (props) => {
  const { footerLogo, footerClass } = props;
  return (
    <footer className={footerClass ? footerClass : "rs-footer"}>
      <div className="footer-top">
        <div className="container">
          <div className="row">
            <div className="col-xl-3 col-lg-3 col-md-4">
              <div className="footer-logo">
                <Link to="/">
                  <img
                    src={footerLogo ? footerLogo : footerLogo1}
                    alt="Logo"
                    style={{
                      //   height: "50px",
                      //   width: "200px",
                      borderRadius: "5px",
                    }}
                  />
                </Link>
              </div>
              <div className="textwidget pb-30">
                <p>
                  We think that by having products immediately ready for
                  shipment, our customers may shorten manufacturing cycles and
                  accelerate time to market.
                </p>
              </div>
              {/* <ul className="footer-social md-mb-30">
                                <li>
                                    <Link to="#"><i className="fa fa-facebook"></i></Link>
                                </li>
                                <li>
                                    <Link to="#"><i className="fa fa-twitter"></i></Link>
                                </li>
                                <li>
                                    <Link to="#"><i className="fa fa-pinterest"></i></Link>
                                </li>
                                <li>
                                    <Link to="#"><i className="fa fa-instagram"></i></Link>
                                </li>
                            </ul> */}
            </div>
            <div className="col-lg-3 col-md-12 col-sm-12 pl-44 md-pl-14 md-mb-30">
              <h3 className="widget-title">Links</h3>
              <ul className="site-map">
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link to="/about">About Us</Link>
                </li>
                <li>
                  <Link to="/contact">RFQ</Link>
                </li>

                <li>
                  <Link to="/featured-capabilities">Featured Capabilities</Link>
                </li>
                <li>
                  <Link to="/service">Services</Link>
                </li>
                <li>
                  <Link to="/terms">Terms</Link>
                </li>
              </ul>
            </div>
            <div className="col-lg-3 col-md-12 col-sm-12 md-mb-30">
              <h3 className="widget-title">Contact Info</h3>
              <ul className="address-widget">
                <li>
                  <i className="flaticon-email"></i>Email
                  <div className="desc">
                    <Link to="mailto:electronicsupport@hifielectro.com">electronicsupport@hifielectro.com</Link>
                  </div>
                </li>
                <li>
                  <i className="flaticon-location"></i>Address
                  <div className="desc">2173 Magwa Crescent Gateway West STE 300, Johannesburg, 2066, South Africa</div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <FooterBottom />
    </footer>
  );
};

export default Footer;

import React from "react";
import BlogPart from "../../components/Blog/HomeBlogSection";
import SectionTitle from "../../components/Common/SectionTitle";

const Blog = () => {
  return (
    <React.Fragment>
      <div id="rs-blog" className="rs-blog pt-108 pb-120 lg-pt-70 lg-pb-70">
        <div className="container">
          <SectionTitle
            sectionClass="sec-title2 text-center mb-44"
            subtitleClass="sub-text"
            subtitle=""
            titleClass="title testi-title"
            title="Terms"
            descClass="desc"
            description={
              <>
                <p>
                  With a minimum order amount of 200 euros, we accept Master
                  Card, Visa, American Express, Discover Card, Money Purchase,
                  Bank Wire Transfer, and Company Check as forms of payment.
                </p>

                <p>
                  You must submit a credit application or a bank letter of
                  credit if you want terms.{" "}
                </p>
                <p>
                  Except for those things for which our pricing is directly
                  wholesale or derived from a written quote based on quantities,
                  all items are priced at their current rates. The terms and
                  circumstances of the collaboration are always subject to
                  modification. Everything is priced ex-works, which means that
                  packaging, freight, insurance, customs taxes, and VAT are not
                  included.</p><p><h3> Returns</h3></p> <p>Returns are permitted within 30 days after
                  the consignment note's issuance. The sender is always
                  responsible for the expense unless we make a mistake. In this
                  instance, our company will pay the shipping costs. Items that
                  have been used or are damaged in any manner are not accepted
                  by us. If the subsequent steps are not followed, there will be
                  no reimbursements.
                </p>
              </>
            }
            effectClass="heading-line"
          />
          <BlogPart />
        </div>
      </div>
    </React.Fragment>
  );
};

export default Blog;

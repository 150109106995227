
import { Link } from 'react-router-dom';

const SinglePost = ( props ) => {
    const {blogClass, blogImage, blogTitle, blogDesc, blogCategory, blogAuthor, blogPublishedDate, blogButtonClass, blogButtonText } = props;
    return (
        <div className={blogClass ? blogClass : 'blog-item mb-44'}>
            <div className="image-wrap" style={{height: 250, width: 350}}>
                <Link to="/blog/blog-details">
                    <img
                        src={blogImage}
                        alt={blogTitle}
                    />
                </Link>
                {/* <ul className="post-categories">
                    <li>
                        <Link to="#">
                            {blogCategory ? blogCategory : 'Application Testing'}
                        </Link>
                    </li>
                </ul> */}
            </div>
            
        </div>

    )
}

export default SinglePost